import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import NavItem from 'react-bootstrap/NavItem';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Routes from './Routes';
import { AppContext } from './libs/contextLib';
import { onError } from './libs/errorLib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isMobile, isAndroid, isFirefox, isSafari } from 'react-device-detect';
import config from './config';

const environment = config.env;

function App() {
  const history = useHistory();

  const phoneNumber = '+359877804825';
  const textMessage = encodeURI('I\'d like to book a locker');
  const coordinates = {
    latitude: 41.820626,
    longitude: 23.478217,
    address: encodeURI('Bears Lairs, ul. "Kosherinata", 2770 кв. Грамадето, Bansko'),
    waypoints: [
      {
        latitude: 41.820112,
        longitude: 23.474020
      }
    ],
    id: 'ChIJcywcFXevqxQRFjKaC0zq1K0'
  };

  const whatsappLink = (isMobile)
    ? (isAndroid)
      ? `intent://send/${phoneNumber}#Intent;scheme=smsto;package=com.whatsapp;action=android.intent.action.SENDTO;end`
      : `https://wa.me/${phoneNumber}?text=${textMessage}`
    : (isFirefox || isSafari)
      ? `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${textMessage}`
      : `whatsapp://send?phone=${phoneNumber}&text=${textMessage}`;

  //const viberLink = `viber://pa?chatURI=bearslairsbansko&context=enquiry&text=${textMessage}`;
  const viberLink = (isAndroid)
    ? `intent://send/${phoneNumber}#Intent;scheme=smsto;package=com.viber;action=android.intent.action.SENDTO;end`
    : `viber://chat?number=${phoneNumber}&text=${textMessage}`;

  const mapsLink = (isAndroid)
    ? `intent://geo:${coordinates.latitude},${coordinates.longitude}?destination=${coordinates.address}/@${coordinates.latitude},${coordinates.longitude}&destination_place_id=${coordinates.id}&waypoints=${coordinates.waypoints.map(wp => (wp.latitude + ',' + wp.longitude)).join('|')}#Intent;scheme=directions;package=com.google.android.apps.maps;action=android.intent.action.ACTION_VIEW;end`
    : `https://www.google.com/maps/dir/?api=1&destination=${coordinates.address}/@${coordinates.latitude},${coordinates.longitude}&destination_place_id=${coordinates.id}&waypoints=${coordinates.waypoints.map(wp => (wp.latitude + ',' + wp.longitude)).join('|')}`


  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);

  useEffect(() => { onLoad(); }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    }
    catch(e) {
      if (e !== 'No current user') {
        onError(e);
      }
    }
    setIsAuthenticating(false);
  }

  async function handleLogout() {
    await Auth.signOut();
    userHasAuthenticated(false);
    history.push('/login');
  }

  return (
    !isAuthenticating &&
    <>
      <Container fluid>
        <Row>
          <LinkContainer to="/" style={{cursor: 'pointer'}}>
            <Image src={'/img/logo.png'} className="m-auto" />
          </LinkContainer>
          {
            (environment !== 'production')
              ? (
                  <span
                    className="pull-right"
                    style={{fontSize: '2em', color: '#ff3399', marginRight: '50px'}}>
                    {environment}
                  </span>
                )
              : null
          }
        </Row>
        <Navbar collapseOnSelect expand="lg">
          <Navbar.Toggle aria-controls="site-nav" />
          <Navbar.Collapse id="site-nav">
            <Nav className="mr-auto">
              {
                /*
                (location.pathname !== '/book')
                  ? (
                      <LinkContainer to="/book">
                        <NavItem className="btn">book now</NavItem>
                      </LinkContainer>
                    )
                  : null
                */
              }
            </Nav>
          </Navbar.Collapse>
          <span>
            <FontAwesomeIcon className="fa-sm fa-flip-horizontal" icon="phone" />
            &nbsp;
            <span className="text-muted">+359</span> <strong>(0) 877 804 825</strong>
            &nbsp;
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <div className="rounded-pill" style={{fontSize: '2em', backgroundColor: '#fff', padding: '20px'}}>
                  <FontAwesomeIcon icon={['fab', 'viber']} color="#665ca7" />
                  &nbsp;
                  Send us a Viber message
                </div>
              }>
              <a href={viberLink}>
                <FontAwesomeIcon icon={['fab', 'viber']} color="#665ca7" />
              </a>
            </OverlayTrigger>
            &nbsp;
            &nbsp;
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <div className="rounded-pill" style={{fontSize: '2em', backgroundColor: '#fff', padding: '20px'}}>
                  <FontAwesomeIcon icon={['fab', 'whatsapp-square']} color="#0dc143" />
                  &nbsp;
                  Send us a WhatsApp message
                </div>
              }>
              <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={['fab', 'whatsapp-square']} color="#0dc143" />
              </a>
            </OverlayTrigger>
            &nbsp;
            &nbsp;
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <div className="rounded-pill" style={{fontSize: '2em', backgroundColor: '#fff', padding: '20px'}}>
                  <FontAwesomeIcon icon={['fab', 'facebook']} color="#4862a3" />
                  &nbsp;
                  Find us on Facebook
                </div>
              }>
              <a href="https://www.facebook.com/BearsLairsBansko" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={['fab', 'facebook']} color="#4862a3" />
              </a>
            </OverlayTrigger>
            &nbsp;
            &nbsp;
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <div className="rounded-pill" style={{fontSize: '2em', backgroundColor: '#fff', padding: '20px'}}>
                  <FontAwesomeIcon icon={['fas', 'map-marked-alt']} color="#cccccc" />
                  &nbsp;
                  Get directions
                </div>
              }>
              <a href={mapsLink} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={['fas', 'map-marked-alt']} color="#ccccc" />
              </a>
            </OverlayTrigger>
          </span>
          <NavDropdown
            className="pull-right"
            title={
              <FontAwesomeIcon className="fa-sm" icon={[(isAuthenticated ? 'fas' : 'far'), 'user']} />
            }
            drop="left">
            {
              isAuthenticated
                ? (
                    <>
                      <LinkContainer to="/bookings">
                        <NavDropdown.Item>bookings</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/profile">
                        <NavDropdown.Item>profile</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/settings/password">
                        <NavDropdown.Item>
                          change password
                        </NavDropdown.Item>
                      </LinkContainer>
                      <NavDropdown.Divider />
                      <NavDropdown.Item onClick={handleLogout}>
                        log-out
                      </NavDropdown.Item>
                    </>
                  )
                : <>
                    <LinkContainer to="/signup">
                      <NavDropdown.Item>
                        sign-up
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/login">
                      <NavDropdown.Item>
                        log-in
                      </NavDropdown.Item>
                    </LinkContainer>
                  </>
            }
          </NavDropdown>
        </Navbar>
      </Container>
      <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
        <Routes />
      </AppContext.Provider>
      <Container id="footer" fluid>
        <Container>
          <Row className="justify-content-xl-center justify-content-md-center">
            <Col xl={6}>
              <LinkContainer to="/privacy">
                <NavItem as="a">
                  privacy
                </NavItem>
              </LinkContainer>
              &nbsp;|&nbsp;
              <LinkContainer to="/terms">
                <NavItem as="a">
                  terms and conditions
                </NavItem>
              </LinkContainer>
              &nbsp;|&nbsp;
              <a href="mailto:info@bearslairs.eu">info@bearslairs.eu</a>
              <hr />
              &copy; 2020, Bears Lairs EOOD, Bulgaria. All rights reserved
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}
export default App;
